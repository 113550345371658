var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{staticClass:"mt-5",attrs:{"spinning":_vm.isLoading}},[_c('a-card',{attrs:{"title":_vm.cardTitle}},[_c('text-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model':_vm.linkModel,
          'key': 'title',
          'prefix': 'invite_link_model_',
          'validation': 'required',
        }
      }}}),_c('chips-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.linkModel,
          'key': 'tags',
          'prefix': 'invite_link_model_',
          'settingWrapper': {
            customiseValue: [],
            hasFieldButton: true,
            disabledValue: []
          }
        }
      }}}),_c('number-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.linkModel.config,
          'key': 'price',
          'prefix': 'invite_link_model_',
          'min': 0,
          'settingWrapper': {
            customiseValue: 0,
            hasFieldButton: true,
            disabledValue: 0
          }
        }
      }}}),(_vm.linkActionType !== 'edit')?_c('div',{staticClass:"relative"},[_c('transition',{attrs:{"name":"default-hide","mode":"out-in"}},[(_vm.linkModel.config.approve !== _vm.InviteLinkApproveType.None || _vm.$store.state.channelsState.activeChannel.config.approve !== _vm.InviteLinkApproveType.None)?_c('a-alert',{staticClass:"absolute z-10 w-full h-full",staticStyle:{"opacity":"0.9"},attrs:{"type":"warning","show-icon":"","message":_vm.$t('invite_linc_disabled_limit_alert_text')}}):_vm._e()],1),_c('number-input',{staticClass:"h-full",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.linkModel.config,
            'key': 'limit',
            'prefix': 'invite_link_model_',
            'min': 0,
            'disabled': _vm.linkModel.config.approve !== _vm.InviteLinkApproveType.None,
            'settingWrapper': {
              customiseValue: 0,
              hasFieldButton: true,
              disabledValue: 0
            }
          }
        }}})],1):_vm._e(),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.linkModel.config,
          'key': 'disable_auto_attack',
          'prefix': 'invite_link_model_',
        },
      }}}),(_vm.$store.getters.isChannelPrivate)?_c('invite-link-action-setting',{attrs:{"approve-type-options":_vm.approveTypeOptions,"fields-show-to":_vm.actionSettingFields,"readonly":_vm.actionSettingFieldsReadonly,"channel-id":_vm.$store.state.channelsState.activeChannel.id},model:{value:(_vm.linkModel.config),callback:function ($$v) {_vm.$set(_vm.linkModel, "config", $$v)},expression:"linkModel.config"}}):_vm._e(),(_vm.linkActionType !== 'edit')?_c('date-picker-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.linkModel.config,
          'key': 'expire',
          'clearable': true,
          'format': _vm.PickerFormat.DateTime,
          'prefix': 'invite_link_',
          'minDate': 'today'
        }
      }}}):_vm._e(),_c('div',{staticClass:"flex justify-end mt-5 gap-2"},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.isSaveButtonDisabled},on:{"click":_vm.handleLinkSaveButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]),_c('a-button',{attrs:{"type":"danger"},on:{"click":_vm.gotoInviteLinksListPage}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)],1),(_vm.linkActionType === 'edit')?_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_remove_for_period_title')}},[_c('a-alert',{staticClass:"mb-4",attrs:{"type":"info","show-icon":""},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('remove_for_period_from_invite_link_title_alert'))}})]},proxy:true}],null,false,323193282)}),_c('div',{staticClass:"w-full flex justify-center"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.goToRemovingUsers}},[_vm._v(" "+_vm._s(_vm.$t('link_page_go_to_remove_for_period_button_text'))+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }